export class QuestionarioModel {
    IdUsuario: string
    JsonPergunta: string
    JsonResposta: string
    JsonRespostaMarcacao: string
    JsonRespostaRealizacao: string
    CodigoExternoAtendimento: string
    DataHoraEnvio: Date
    Enviado: boolean
    DataHoraCriacao: Date
    NomePaciente: string
    DescricaoQuestionario: string
    SincronizadoBaseExternaCliente: boolean
    UrlSite: string
    PreparoExame: string
    Processando: boolean
    Respondido: boolean
    ExisteJsonPerguntaMarcacao: boolean
    ExisteJsonPerguntaRealizacao: boolean
    AgendamentoRespondido: boolean
    AgendamentoEnviado: boolean
    Concluido: boolean
}