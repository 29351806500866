import { Component, OnInit, ElementRef } from '@angular/core';
declare let $: any;
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-questionario-concluido',
    templateUrl: './questionario-concluido.component.html',
    styleUrls: ['./questionario-concluido.component.scss'],
})

export class QuestionarioConcluidoComponent implements OnInit {

    constructor() {

    }

    ngOnInit(): void {

    }

    ngOnDestroy() {
    }

    goDadosBeneficiario() {
    }
}