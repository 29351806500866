import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { environment } from "src/environments/environment";
import { QuestionarioModel } from "../models/questionario.model";
import { RetornoServiceModel } from "../models/retorno-service.model";
import { QuestionarioProvider } from "../providers/questionario.provider";

@Injectable({
    providedIn: "root"
})
export class QuestionarioHomeGuard implements CanActivate {

    codigoQuestionario: string;
    orgId: string = environment.orgId;
    questionario: QuestionarioModel = new QuestionarioModel();

    constructor(private router: Router, private QuestionarioProvider: QuestionarioProvider, private toastr: ToastrService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        var that = this;
        that.codigoQuestionario = next.params['codigoQuestionario'];

        return that.QuestionarioProvider.GetQuestionario(this.codigoQuestionario, "todos").toPromise().then(function (response: RetornoServiceModel) {

            if (response.Value) {
                that.questionario = Object.assign(that.questionario, response.Value);

                if (!that.questionario.AgendamentoRespondido) {
                    if (!that.questionario.Concluido) {
                        return true;
                    } else {
                        that.router.navigate(['/questionario-concluido/']);
                        return false;
                    }
                } else {
                    that.router.navigate(['/home/realizacao/' + that.codigoQuestionario]);
                    return false;
                }
            } else {
                that.router.navigate(['/pagina-nao-encontrada/']);
                return false;
            }

        }).catch(function (error) {
            that.toastr.error("Ocorreu um erro ao recuperar o questionario. Por favor, tente novamente", "Atenção!");

            return false;
        });
    }
}