import { Component, OnInit, ElementRef } from '@angular/core';
declare let $: any;
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-pagina-nao-encontrada',
    templateUrl: './pagina-nao-encontrada.component.html',
    styleUrls: ['./pagina-nao-encontrada.component.scss'],
})

export class PaginaNaoEncontradaComponent implements OnInit {

    constructor() {

    }

    ngOnInit(): void {

    }

    ngOnDestroy() {
    }

    goDadosBeneficiario() {
    }
}