<div class="bg-page-not-found content">
    <div class="div-not-found">
        <div class="margin-auto col-md-6 col-sm-12 col-xs-12 text-center">
            <img class="img-not-found" src="../../../assets/img/not-found-icon.png" />
            <div class="margin-top-40">
                <h3 class="titulo-pagina-nao-encontrada">Página não encontrada</h3>
                <p class="margin-top-20 subtitulo-pagina-nao-encontrada">
                    A página que você tentou acessar não existe ou não está disponível no momento.
                    Verifique se você digitou corretamente o endereço desejado e tente novamente.
                </p>
            </div>
        </div>
    </div>
</div>