<div class="bg-page-not-found content">
    <div class="div-not-found">
        <div class="margin-auto col-md-6 col-sm-12 col-xs-12 text-center">
            <img class="img-not-found" src="../../../assets/img/checkmark.svg" />
            <div class="margin-top-40">
                <h3 class="titulo-pagina-nao-encontrada">Questionário concluído</h3>
                <p class="margin-top-20 subtitulo-pagina-nao-encontrada">
                    O questionário que você está tentando acessar já foi concluído e não existe perguntas para serem
                    respondidas.
                </p>
            </div>
        </div>
    </div>
</div>