
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionarioGuard } from './guardas/questionario.guard';
import { PaginaNaoEncontradaComponent } from './pages/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { QuestionarioConcluidoComponent } from './pages/questionario-concluido/questionario-concluido.component';

const routes: Routes = [
  // utilizar o app.module
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }