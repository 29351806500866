import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'QuestionarioOnline';

  routeEntries = [
    { path: '/home' },
    /* DEFAULT ROUTE */
    { path: '/home/realizacao' },
    /* NOT FOUND ROUTE */
    { path: '/home/agendamento' }
  ];

  constructor(private router: Router) {

    // router.events.subscribe((val) => {
    //   let routerLink = location.pathname;

    //   let routerLinkLower = routerLink.toLowerCase();

    //   routerLinkLower = routerLinkLower.substring(routerLink.indexOf('/'), routerLink.lastIndexOf('/'));

    //   // if the route does not exist, check for the lowercase version
    //   if (!this.routeEntries.find((route) => { return route.path === routerLinkLower; })) {
    //     this.router.navigate([routerLinkLower]);
    //   }
    // });
  }
}
