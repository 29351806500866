import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment'
import * as moment from 'moment';
import { RetornoServiceModel } from '../models/retorno-service.model';
import { QuestionarioModel } from '../models/questionario.model';


@Injectable()
export class QuestionarioProvider {

    private urlGetQuestionario: string = `${environment.ApiUrl}api/Questionario/GetQuestionario?codigoExternoAtendimento=`;
    private urlExisteQuestionarioAtendimento: string = `${environment.ApiUrl}api/Questionario/ExisteQuestionarioAtendimento?codigoExternoAtendimento=`;
    private urlAtualizarRespostaQuestionario: string = `${environment.ApiUrl}api/Questionario/AtualizarRespostaQuestionario`;
    private urlEnviarRespostaQuestionario: string = `${environment.ApiUrl}api/Questionario/EnviarRespostaQuestionario`;
    private urlBuscarDadosCliente: string = `${environment.ApiUrl}api/Questionario/BuscarDadosCliente?urlSite=`;
    private urlGetMensagemTermoResponsabilidade: string = `${environment.ApiUrl}api/Questionario/MensagemTermoResponsabilidade/`;

    
    constructor(private http: HttpClient) {

    }

    BuscarDadosCliente(): Observable<RetornoServiceModel> {

        return this.http.post<any>(this.urlBuscarDadosCliente + environment.orgId, null).pipe(
            map(response => {
                return response.Data;
            },
                error => this.handleError(error))
        );
    }
    
    ExisteQuestionarioAtendimento(codigoQuestionario: string): Observable<RetornoServiceModel> {

        return this.http.post<any>(this.urlExisteQuestionarioAtendimento + codigoQuestionario + "&urlSite=" + environment.orgId, null).pipe(
            map(response => {
                return response.Data;
            },
                error => this.handleError(error))
        );
    }

    GetMensagemTermoResponsabilidade(): any {

        return  this.http.get<any>(this.urlGetMensagemTermoResponsabilidade+ "?urlSite=" + environment.orgId).pipe(
           map(response => {
               return response.Data;
            },
               error => this.handleError(error))
       );
    }

    GetQuestionario(codigoQuestionario: string, urlOrigem?: string): Observable<RetornoServiceModel> {

        return this.http.post<any>(this.urlGetQuestionario + codigoQuestionario + "&urlSite=" + environment.orgId + "&urlOrigem=" + urlOrigem, null).pipe(
            map(response => {
                return response.Data;
            },
                error => this.handleError(error))
        );
    }

    AtualizarRespostaQuestionario(questionario: QuestionarioModel, urlOrigem: string): any {

        let headerParams = { 'Content-Type': 'application/json' }
        let headers = { headers: new HttpHeaders(headerParams) };
    
        return this.http.post<any>(this.urlAtualizarRespostaQuestionario + "?urlOrigem=" + urlOrigem + "&urlSite=" + environment.orgId, questionario, headers).pipe(
            map(response => {
                return response.Data;
            },
                error => this.handleError(error))
        );
    }

    EnviarRespostaQuestionario(questionario: QuestionarioModel, urlOrigem: string): any {

        var questionarioModel = new QuestionarioModel();
        questionarioModel.IdUsuario = questionario.IdUsuario;
        questionarioModel.JsonPergunta = questionario.JsonPergunta;
        questionarioModel.JsonResposta = questionario.JsonResposta;
        questionarioModel.CodigoExternoAtendimento = questionario.CodigoExternoAtendimento;
        questionarioModel.DataHoraEnvio = questionario.DataHoraEnvio;
        questionarioModel.Enviado = questionario.Enviado;
        questionarioModel.DataHoraCriacao = questionario.DataHoraCriacao;
        questionarioModel.NomePaciente = questionario.NomePaciente;
        questionarioModel.DescricaoQuestionario = questionario.DescricaoQuestionario;
        questionarioModel.SincronizadoBaseExternaCliente = questionario.SincronizadoBaseExternaCliente;
        questionarioModel.UrlSite = questionario.UrlSite;
        questionarioModel.PreparoExame = questionario.PreparoExame;
        questionarioModel.Processando = questionario.Processando;
        questionarioModel.Enviado = true;

        let headerParams = { 'Content-Type': 'application/json' }
        let headers = { headers: new HttpHeaders(headerParams) };
    
        return this.http.post<any>(this.urlEnviarRespostaQuestionario + "?urlOrigem=" + urlOrigem + "&urlSite=" + environment.orgId, questionarioModel, headers).pipe(
            map(response => {
                return response.Data;
            },
                error => this.handleError(error))
        );
    }

    private handleError(error: any): Observable<any> {

        if (error.status === 0) {
            return of('Erro de conexão.');
        }
        let errMsg: string;
        if (error instanceof HttpErrorResponse) {
            const err = error.message || JSON.stringify(error.error);
            errMsg = `${error.status} - ${error.statusText || ''} Details: ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
            console.log(JSON.stringify(errMsg));
            return of(error);
        }
    }

}